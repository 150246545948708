import * as Sentry from '@sentry/react';
import React from 'react';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error('ErrorBoundary caught an error:', error, errorInfo);
		Sentry.captureException(error);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div style={{ padding: '20px', textAlign: 'center' }}>
					<h1>Oops! Something went wrong.</h1>
					<p>
						We're sorry for the inconvenience. Please try refreshing the page or contact support if
						the problem persists.
					</p>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
