import * as Sentry from '@sentry/react';

export const handleApiError = (error) => {
	console.error('API Error:', error);

	if (error.status === 34) {
		// Handle specific error for status code 34
		console.warn('Network request failed with status 34');
		// You can add custom logic here, such as showing a user-friendly message
	}

	// Log the error to Sentry
	Sentry.captureException(error);

	// You can add more specific error handling logic here based on error types or status codes

	// Rethrow the error if you want it to be caught by the global error handler
	throw error;
};

export const apiRequestInterceptor = (config) => {
	// You can modify the request config here if needed
	return config;
};

export const apiResponseInterceptor = (response) => {
	// You can modify the response here if needed
	return response;
};

export const apiErrorInterceptor = (error) => {
	return Promise.reject(handleApiError(error));
};
