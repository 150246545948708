import { CheckCircleTwoTone } from '@ant-design/icons';
import { bindAll } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Photo from '../pouchDB/models/Photo';
import '../styles/Gallery.sass';
import FullScreenIndicator from './FullScreenIndicator';

class PhotoComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			imageURL: null
		};
		bindAll(this, ['loadImage', 'visibilityChanged']);
	}

	componentDidMount() {
		const { isThumbnail, eventThumbnail } = this.props;

		if (!isThumbnail && !eventThumbnail) {
			//For fullscreen load image directly
			this.visibilityChanged(true);
		}
	}

	visibilityChanged(isVisible) {
		if (isVisible && !this.state.loading && !this.state.imageURL) {
			const { photo, logoutNow, isThumbnail } = this.props;
			this.imageURL = undefined;
			this.setState({ loading: true });
			this.loadImage(Photo.getS3Key(photo), isThumbnail, logoutNow);
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (this.props.photo.s3FileName === newProps.photo.s3FileName) {
			return;
		}
		console.log('PhotoComp: Photo changed on same mounted Photo.');
		this.setState({ loading: true });
		this.loadImage(Photo.getS3Key(newProps.photo), newProps.isThumbnail, newProps.logoutNow);
	}

	loadImage(s3Key, isThumbnail, logoutNow) {
		Photo.getPhoto(s3Key, isThumbnail)
			.then((image) => {
				this.setState({ loading: false, imageURL: image });
			})
			.catch((errResponse) => {
				this.setState({ loading: false, imageURL: null });

				console.log(errResponse);
				if (errResponse && (errResponse.status === 401 || errResponse.status === 403)) {
					if (logoutNow) {
						logoutNow();
					}
				}
			});
	}

	render() {
		const { className, onClick, isThumbnail, isSelected, eventThumbnail } = this.props;
		let imageURL = this.state.imageURL;

		if (eventThumbnail) {
			return (
				<div
					className={'event-thumbnail ' + className}
					style={{ backgroundImage: 'url(' + imageURL + ')' }}
					onClick={onClick}
				/>
			);
		}

		if (isThumbnail) {
			return (
				<VisibilitySensor onChange={this.visibilityChanged} partialVisibility={true}>
					<div
						className={'thumbnail ' + className}
						style={{ backgroundImage: 'url(' + imageURL + ')' }}
						onClick={onClick}>
						{isSelected ? (
							<div style={{ float: 'right', margin: '8px' }}>
								<CheckCircleTwoTone twoToneColor="#6F4898" style={{ fontSize: '18px' }} />
							</div>
						) : null}
					</div>
				</VisibilitySensor>
			);
		}

		if (this.state.loading) {
			return <FullScreenIndicator />;
		}

		return (
			<div className="fullscreen-img carousel-img-wrap">
				<img className="carousel-img" src={imageURL} onClick={onClick} alt="" />
			</div>
		);
	}
}

PhotoComp.propTypes = {
	onClick: PropTypes.func,
	photo: PropTypes.shape({
		event: PropTypes.string.isRequired,
		s3FileName: PropTypes.string.isRequired
	}).isRequired,
	isThumbnail: PropTypes.bool,
	eventThumbnail: PropTypes.bool,
	logoutNow: PropTypes.func
};

export default PhotoComp;
