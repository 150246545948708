import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import FullScreenIndicator from '../components/FullScreenIndicator';
import App from './App';

const Root = ({ store, history }) => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Suspense fallback={<FullScreenIndicator />}>
				<>
					<Route path="/" component={App} />
				</>
			</Suspense>
		</ConnectedRouter>
	</Provider>
);

Root.propTypes = {
	store: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default Root;
