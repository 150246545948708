import fetch from 'isomorphic-fetch';

// Regular expressions for video ID extraction
const RE_VIMEO = /^(?:\/video|\/channels\/[\w-]+|\/groups\/[\w-]+\/videos)?\/(\d+)$/;
const RE_YOUTUBE = /^(?:\/embed)?\/([\w-]{10,12})$/;

export const VideoSourceTypes = Object.freeze({
	YOUTUBE: 1,
	VIMEO: 2
});

/**
 * Safely creates a URL object and returns null if invalid
 * @param {string} urlString
 * @returns {URL|null}
 */
const createSafeURL = (urlString) => {
	try {
		return new URL(urlString);
	} catch (error) {
		console.warn('Invalid URL:', error);
		return null;
	}
};

/**
 * Extracts YouTube video ID from various YouTube URL formats
 * @param {URL} urlObj
 * @returns {string|null}
 */
const extractYoutubeVideoId = (urlObj) => {
	try {
		// Check for v parameter in query string
		const searchParams = new URLSearchParams(urlObj.search);
		const queryVideoId = searchParams.get('v');
		if (queryVideoId?.match(/^[\w-]{10,12}$/)) {
			return queryVideoId;
		}

		// Check for youtu.be format
		if (urlObj.hostname === 'youtu.be') {
			const pathVideoId = urlObj.pathname.slice(1);
			if (pathVideoId.match(/^[\w-]{10,12}$/)) {
				return pathVideoId;
			}
		}

		// Check for embedded format
		const match = RE_YOUTUBE.exec(urlObj.pathname);
		if (match) {
			return match[1];
		}

		return null;
	} catch (error) {
		console.warn('Error extracting YouTube video ID:', error);
		return null;
	}
};

/**
 * Handles YouTube thumbnail retrieval
 * @param {URL} urlObj
 * @returns {Promise<{url: string, source: number}>}
 */
const handleYouTube = (urlObj) => {
	const videoId = extractYoutubeVideoId(urlObj);
	if (videoId) {
		return Promise.resolve({
			url: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
			source: VideoSourceTypes.YOUTUBE
		});
	}
	return Promise.resolve({ url: undefined, source: undefined });
};

/**
 * Handles Vimeo thumbnail retrieval
 * @param {URL} urlObj
 * @returns {Promise<{url: string, source: number}>}
 */
const handleVimeo = async (urlObj) => {
	const match = RE_VIMEO.exec(urlObj.pathname);
	if (!match) {
		return { url: undefined, source: undefined };
	}

	const videoId = match[1];
	try {
		const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
		if (!response.ok) {
			throw new Error(`Vimeo API error: ${response.status}`);
		}

		const json = await response.json();
		if (!json || json.length === 0) {
			throw new Error('Invalid Vimeo response');
		}

		return {
			url: json[0].thumbnail_large,
			source: VideoSourceTypes.VIMEO
		};
	} catch (error) {
		console.warn('Vimeo thumbnail fetch error:', error);
		return { url: undefined, source: undefined };
	}
};

/**
 * Gets thumbnail URL for various video platforms
 * @param {string} url
 * @param {string} [fbToken]
 * @returns {Promise<{url: string|undefined, source: number|undefined}>}
 */
export const getThumbnailURL = async (url, fbToken) => {
	if (!url) {
		return { url: undefined, source: undefined };
	}

	const urlObj = createSafeURL(url);
	if (!urlObj) {
		return { url: undefined, source: undefined };
	}

	const hostname = urlObj.hostname.toLowerCase();

	// YouTube handling
	if (['www.youtube.com', 'youtube.com', 'youtu.be'].includes(hostname)) {
		return handleYouTube(urlObj);
	}

	// Vimeo handling
	if (['www.vimeo.com', 'vimeo.com', 'player.vimeo.com'].includes(hostname)) {
		return handleVimeo(urlObj);
	}

	// Return undefined for unsupported platforms
	return { url: undefined, source: undefined };
};
