export const BUCKET_NAME = 'kamero-mumbai';
export const SYNC_GATEWAY_URL = 'https://login.kamero.in/kamero/';
export const RAZOR_CLIENT_KEY = 'rzp_live_8ta1kRwHSMTAZM';
// export const IMAGES_URL = 'https://images.kamero.in/';
export const IMAGES_URL = 'https://pic.kamero.ai/';

export const FULLL_IMAGE_URL = 'https://image.kamero.in/';

export const RENEWAL_BASE_URL = 'https://event.kamero.in/';

export const API_BASE_URL = 'https://api.kamero.ai/';

export const IS_DEVELOPMENT = false;

export const SENTRY_AUTH_TOKEN =
	'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';
