import React from 'react';
import '../styles/Layouts.sass';
import { ReactComponent as IndicatorSVG } from '../svg/Indicator.svg';

const FullScreenIndicator = () => {
	return (
		<div
			className="flexCenterFullScreenContainer"
			style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
			<div className="flexCenterPageChild">
				<IndicatorSVG />
			</div>
		</div>
	);
};

export default FullScreenIndicator;
