//new swagger client

import Swagger from 'swagger-client';
import { apiRequestInterceptor, apiResponseInterceptor, handleApiError } from '../utils/apiUtils';

let clientInternal = undefined;

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const configureSwaggerClient = async (retryCount = 0) => {
	try {
		const client = await Swagger('/swagger.json');
		if (client.errors.length > 0) {
			console.log(client.errors);
		}
		client.requestInterceptor = apiRequestInterceptor;
		client.responseInterceptor = apiResponseInterceptor;
		clientInternal = client;
		return client;
	} catch (error) {
		console.error(`Swagger client initialization failed (attempt ${retryCount + 1}):`, error);
		if (retryCount < MAX_RETRIES) {
			console.log(`Retrying in ${RETRY_DELAY / 1000} seconds...`);
			await delay(RETRY_DELAY);
			return configureSwaggerClient(retryCount + 1);
		} else {
			console.error('Max retries reached. Swagger client initialization failed.');
			throw error;
		}
	}
};

export const swaggerClient = () => {
	return clientInternal;
};

export const loginWithFB = async (token) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.register({
			'auth-type': 0,
			token: token
		});
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const loginWithCredentials = async (email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.login({
			email: email,
			password: password,
			otp: otp
		});
		const response = JSON.parse(result.data);
		if (!response || !response.userId) {
			throw new Error('Invalid response from server');
		}
		return response;
	} catch (err) {
		console.error('Login error:', err);
		throw errorHandler(err);
	}
};

export const verifySession = async () => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.verify_session();
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const sendOTP = async (email) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.send_otp({ email: email });
	} catch (err) {
		errorHandler(err);
	}
};

export const SignupResponse = Object.freeze({
	success: 1,
	needsOTP: 2,
	goToSetPassword: 3,
	error: 4,
	invalidOTP: 5
});

export const signup = async (name, email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.signup({
			name: name,
			password: password,
			email: email,
			otp: otp
		});
		return SignupResponse.success;
	} catch (err) {
		console.log(err);
		if (err.status === 409) {
			return SignupResponse.goToSetPassword;
		} else if (err.status === 425) {
			return SignupResponse.needsOTP;
		} else if (err.status === 401) {
			return SignupResponse.invalidOTP;
		} else {
			let response = JSON.parse(err.response.text);
			if (response && response.message) {
				return response.message;
			}
			return 'An unexpected error occurred. Please try again.';
		}
	}
};

// Replace the existing errorHandler with our new handleApiError function
export const errorHandler = handleApiError;

export const updateUserPhoneNumber = async (userId, phoneNumber) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.updatePhone({
			userId: userId,
			phone: phoneNumber
		});
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};
