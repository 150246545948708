import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import { createBrowserHistory } from 'history';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { clearEventHome } from './actions/pouchDBActions';
import ErrorBoundary from './components/ErrorBoundary';
import FullScreenIndicator from './components/FullScreenIndicator';
import Root from './containers/Root';
import configureStore from './store/configureStore';
import { theme } from './theme';

Sentry.init({
	dsn: 'https://e9a2e0b6300aa35d803abafd625a33f7@o4508313696403456.ingest.us.sentry.io/4508313697910784',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function noop() {}

console.log('Environment: ' + process.env.REACT_APP_KAMERO_ENV);
if (process.env.REACT_APP_KAMERO_ENV === 'production') {
	console.log = noop;
	console.warn = noop;
	console.error = noop;
	console.timeEnd = noop;
	console.time = noop;
}

const history = createBrowserHistory();
export const store = configureStore(history);

const root = createRoot(document.getElementById('root'));
root.render(
	<ErrorBoundary>
		<Suspense fallback={<FullScreenIndicator />}>
			<Router>
				<Suspense fallback={<FullScreenIndicator />}>
					<ConfigProvider theme={theme}>
						<Root store={store} history={history} />
					</ConfigProvider>
				</Suspense>
			</Router>
		</Suspense>
	</ErrorBoundary>
);

// Check for browser compatibility
const isCompatible = () => {
	const ua = window.navigator.userAgent;
	const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
	const webkit = !!ua.match(/WebKit/i);
	const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

	if (iOSSafari) {
		const v = (ua.match(/(?:Version)\/(\d+)\./) || [])[1];
		if (v < 12) {
			alert(
				'Your browser version is not supported. Please update to the latest version of Safari.'
			);
			return false;
		}
	}
	return true;
};

if (!isCompatible()) {
	console.error('Browser not compatible');
	document.body.innerHTML =
		'<p>Your browser is not compatible with this application. Please update to the latest version or try a different browser.</p>';
}

let watchingHistory = false;
const watchHistory = () => {
	if (history && watchingHistory === false) {
		watchingHistory = true;
		history.listen((location) => {
			// You can also subscribe to changes in the location!
			if (location.pathname === '/events') {
				console.log('History Event: /events');
				clearEventHome(store.dispatch, store.getState);
			}
		});
	}
};

watchHistory();
